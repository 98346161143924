<template>
  <PageHeader class="header">Юмор</PageHeader>
  <h4>тут будет околоспортивный юмор</h4>
</template>

<script>
import PageHeader from "@/components/main/PageHeader";

export default {
  name: "HumorPage",
  components: {
    PageHeader
  }
}
</script>

<style scoped>

</style>