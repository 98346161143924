<template>
  <p>{{$route.params.type}}</p>
  <p>{{$route.params.event}}</p>

  <h3>Личный зачёт</h3>
  <h3>Кубок конструкторов</h3>
</template>

<script>
export default {
  name: "RaceTable"
}
</script>

<style scoped>

</style>